/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BreadCrumps from '../components/BreadCrumps'

function About() {
  return (
    <div>

      {/* Page Header Start */}
      <BreadCrumps page='Nosotros' title='Nosotros'/>
      {/* Page Header End */}

      {/* About Start */}
      <div className="container-fluid py-5">
            <div className="container">
                <div className="row align-items-center pb-1">
                    <div className="col-lg-5">
                        <img className="img-thumbnail p-3" src="assets/img/about_us.jpg" alt="About Us"/>
                    </div>
                    <div className="col-lg-7 mt-5 mt-lg-0">
                        <small className="bg-primary text-white text-uppercase font-weight-bold px-1">¿Quiénes somos?</small>
                        <h1 className="mt-2 mb-4">Empresa de Software & AI</h1>
                        <p className="mb-4">AI-co worker es una empresa que ayuda a otros negocios a mejorar desde productividad, automatizar procesos y diseñarles una arquitectura en la nube, la cual les beneficia en reducción de costos y escalabilidad del mismo. Así mismo trabajamos en la innovación haciendo uso de la AI y Big Data.</p>
                        <a href="/contact" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">Conoce más</a>
                    </div>
                </div>
            </div>
      </div>
      {/* About End */}

      {/* Misión, Visión y Valores Start */}
      <div className="container-fluid py-5 bg-light">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <h2 className="mb-4">Misión</h2>
                    <p>Nuestra misión es proporcionar productos y servicios de tecnología de alta calidad que satisfagan las necesidades de nuestros clientes, contribuyendo a la generación, implementación y mantenimiento de herramientas para negocios que las necesiten.</p>
                </div>
                <div className="col-lg-4">
                    <h2 className="mb-4">Visión</h2>
                    <p>Ser una empresa líder en el ámbito de servicios B2B de AI y Big Data, reconocida por nuestra innovación, calidad y compromiso con la excelencia.
                    </p>
                </div>
                <div className="col-lg-4">
                    <h2 className="mb-4">Valores</h2>
                    <ul>
                        <li>Integridad</li>
                        <li>Innovación</li>
                        <li>Colaboración</li>
                        <li>Servicio al cliente</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      {/* Misión, Visión y Valores End */}

      {/* Features Start */}
    <div className="container-fluid pt-5 pb-2">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 mb-5">
                    <small className="bg-primary text-white text-uppercase font-weight-bold px-1">¿Por qué nosotros?</small>
                    <h1 className="mt-2 mb-3">Productos innovadores</h1>
                    <h4 className="font-weight-normal text-muted mb-4">Transformamos tus ideas en soluciones tecnológicas únicas. Nuestros productos están diseñados para impulsar tu crecimiento, 
                      optimizar procesos y materializar tus sueños con tecnología de vanguardia. Confía en nosotros para hacer realidad el futuro que imaginas.</h4>
                    <div className="list-inline mb-4">
                        <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Con AI predice y reduce riesgos.</p>
                        <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Inteligencia de negocio para mejorar la experiencia.</p>
                        <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Explotación de bases de datos.</p>
                        <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Extracción de datos para su uso.</p>
                    </div>
                    <a href="/products" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">Leer más</a>
                </div>
                <div className="col-lg-6">
                    <div className="row">
                        <div className="col-sm-6 pb-1">
                            <div className="d-flex flex-column align-items-center border px-4 mb-4">
                                <h2 className="display-3 text-primary mb-3"><i className="fas fa-lightbulb"></i></h2>
                                <h5 className="font-weight-bold mb-4">Innovación constante</h5>
                                <p className="text-center">"Nos apasiona llevar soluciones tecnológicas innovadoras a nuestros clientes."</p>
                            </div>
                        </div>
                        <div className="col-sm-6 pb-1">
                            <div className="d-flex flex-column align-items-center border px-4 mb-4">
                                <h2 className="display-3 text-primary mb-3"><i className="fas fa-user-friends"></i></h2>
                                <h5 className="font-weight-bold mb-4">Atención personalizada</h5>
                                <p className="text-center">"Cada proyecto es único, y lo tratamos con dedicación y atención al detalle."</p>
                            </div>
                        </div>
                        <div className="col-sm-6 pb-1">
                            <div className="d-flex flex-column align-items-center border px-4 mb-4">
                                <h2 className="display-3 text-primary mb-3"><i className="fas fa-handshake"></i></h2>
                                <h5 className="font-weight-bold mb-4">Compromiso garantizado</h5>
                                <p className="text-center">"Nos comprometemos a cumplir con excelencia en cada entrega."</p>
                            </div>
                        </div>
                        <div className="col-sm-6 pb-1">
                            <div className="d-flex flex-column align-items-center border px-4 mb-4">
                                <h2 className="display-3 text-primary mb-3"><i className="fas fa-chart-line"></i></h2>
                                <h5 className="font-weight-bold mb-4">Crecimiento conjunto</h5>
                                <p className="text-center">"Estamos creciendo contigo, construyendo un futuro tecnológico."</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Features End */}

    </div>
  )
}

export default About

import React from 'react';
import BreadCrumps from '../components/BreadCrumps';

// Componente reutilizable para las opciones de inicio de sesión
const LoginBox = ({ title, onClick }) => (
  <div className="login-container text-center">
    <h2>{title}</h2>
    <div className="button-container">
      <button type="button" className="btn btn-primary" onClick={onClick}>
        Ingresar
      </button>
    </div>
  </div>
);

function Login() {
  const handleClientLogin = () => {
    // Lógica para redirigir al inicio de sesión para clientes
    window.location.href = '/cliente-login';
  };

  const handleEmployeeLogin = () => {
    // Lógica para redirigir al inicio de sesión para empleados
    window.location.href = '/empleado-login';
  };

  return (
    <div>
      {/* BreadCrumps Start */}
      <BreadCrumps page="Iniciar Sesión" title="Iniciar Sesión" />
      {/* BreadCrumps End */}

      {/* Login Section */}
      <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
        <div className="row w-100">
          <div className="col-md-6">
            <LoginBox title="Clientes" onClick={handleClientLogin} />
          </div>
          <div className="col-md-6">
            <LoginBox title="Empleados" onClick={handleEmployeeLogin} />
          </div>
        </div>
      </div>
      {/* Login Section End */}
    </div>
  );
}

export default Login;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

function BreadCrumps(props) {
  return (
    <div>
      {/* Page Header Start */}
      <div className="container-fluid page-header d-flex flex-column align-items-center justify-content-center pt-3 pb-3 mb-3">
        <h1 className="display-4 text-white mb-2">{props.title}</h1>
        <div className="d-inline-flex text-white">
          <p className="m-0"><a className="text-white" href="#">Inicio</a></p>
          <p className="m-0 px-2">/</p>
          <p className="m-0">{props.page}</p>
        </div>
      </div>
      {/* Page Header End */}
    </div>
  )
}

export default BreadCrumps
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

function Home() {
  return (
    <div>

      {/* Carousel Start */}
      <div className="container-fluid p-0 mb-5">
        <div id="header-carousel" className="carousel slide carousel-fade" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#header-carousel" data-slide-to="0" className="active"></li>
            <li data-target="#header-carousel" data-slide-to="1"></li>
            <li data-target="#header-carousel" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="img-fluid" src="assets/img/changes1.jpg" alt="Image" />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div className="p-5" style={{ width: '100%', maxWidth: '900px' }}>
                  <h5 className="text-white text-uppercase mb-md-3">Empresa de AI</h5>
                  <h1 className="display-3 text-white mb-md-4">Tu compañero en tus tareas diarias</h1>
                  <a href="/about" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2">Conoce más</a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="img-fluid" src="assets/img/changes2.jpg" alt="Image" />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div className="p-5" style={{ width: '100%', maxWidth: '900px' }}>
                  <h5 className="text-white text-uppercase mb-md-3">Empresa de AI</h5>
                  <h1 className="display-3 text-white mb-md-4">Mejora la productividad</h1>
                  <a href="/products" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2">Conoce más</a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="img-fluid" src="assets/img/changes3.jpg" alt="Image" />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div className="p-5" style={{ width: '100%', maxWidth: '900px' }}>
                  <h5 className="text-white text-uppercase mb-md-3">Empresa de AI</h5>
                  <h1 className="display-3 text-white mb-md-4">Crea desarrollos a la medida</h1>
                  <a href="/products/custom" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2">Conoce más</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Carousel End */}

      {/* About Start */}
      <div className="container-fluid py-5">
            <div className="container">
                <div className="row align-items-center pb-1">
                    <div className="col-lg-5">
                        <img className="img-thumbnail p-3" src="assets/img/about.jpg" alt=""/>
                    </div>
                    <div className="col-lg-7 mt-5 mt-lg-0">
                        <small className="bg-primary text-white text-uppercase font-weight-bold px-1">¿Qué hacemos?</small>
                        <h1 className="mt-2 mb-4">Ofrecemos soluciones creativas con AI y Big Data</h1>
                        <p className="mb-4">Brindamos a los distintos negocios las herramientas para uso de datos y explotar estos mismos.</p>
                        <a href="/products/custom" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">Ver más</a>
                    </div>
                </div>
            </div>
        </div>
      {/* About End */}

     {/* Features Start */}
    <div className="container-fluid pt-5 pb-2">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 mb-5">
                    <small className="bg-primary text-white text-uppercase font-weight-bold px-1">¿Por qué nosotros?</small>
                    <h1 className="mt-2 mb-3">Productos innovadores</h1>
                    <h4 className="font-weight-normal text-muted mb-4">Transformamos tus ideas en soluciones tecnológicas únicas. Nuestros productos están diseñados para impulsar tu crecimiento, 
                      optimizar procesos y materializar tus sueños con tecnología de vanguardia. Confía en nosotros para hacer realidad el futuro que imaginas.</h4>
                    <div className="list-inline mb-4">
                        <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Con AI predice y reduce riesgos.</p>
                        <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Inteligencia de negocio para mejorar la experiencia.</p>
                        <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Explotación de bases de datos.</p>
                        <p className="font-weight-semi-bold mb-2"><i className="fa fa-angle-right text-primary mr-2"></i>Extracción de datos para su uso.</p>
                    </div>
                    <a href="/products" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold">Leer más</a>
                </div>
                <div className="col-lg-6">
                    <div className="row">
                        <div className="col-sm-6 pb-1">
                            <div className="d-flex flex-column align-items-center border px-4 mb-4">
                                <h2 className="display-3 text-primary mb-3"><i className="fas fa-lightbulb"></i></h2>
                                <h5 className="font-weight-bold mb-4">Innovación constante</h5>
                                <p className="text-center">"Nos apasiona llevar soluciones tecnológicas innovadoras a nuestros clientes."</p>
                            </div>
                        </div>
                        <div className="col-sm-6 pb-1">
                            <div className="d-flex flex-column align-items-center border px-4 mb-4">
                                <h2 className="display-3 text-primary mb-3"><i className="fas fa-user-friends"></i></h2>
                                <h5 className="font-weight-bold mb-4">Atención personalizada</h5>
                                <p className="text-center">"Cada proyecto es único, y lo tratamos con dedicación y atención al detalle."</p>
                            </div>
                        </div>
                        <div className="col-sm-6 pb-1">
                            <div className="d-flex flex-column align-items-center border px-4 mb-4">
                                <h2 className="display-3 text-primary mb-3"><i className="fas fa-handshake"></i></h2>
                                <h5 className="font-weight-bold mb-4">Compromiso garantizado</h5>
                                <p className="text-center">"Nos comprometemos a cumplir con excelencia en cada entrega."</p>
                            </div>
                        </div>
                        <div className="col-sm-6 pb-1">
                            <div className="d-flex flex-column align-items-center border px-4 mb-4">
                                <h2 className="display-3 text-primary mb-3"><i className="fas fa-chart-line"></i></h2>
                                <h5 className="font-weight-bold mb-4">Crecimiento conjunto</h5>
                                <p className="text-center">"Estamos creciendo contigo, construyendo un futuro tecnológico."</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Features End */}

      
    </div>


     
  )
}

export default Home